import React from "react"
import {
  Table,
  Paper,
  TableContainer,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Box,
  Collapse,
  IconButton,
  Typography,
} from "@material-ui/core"
import { OpenInNew as OpenInNewIcon } from "@material-ui/icons"
import { withStyles, createStyles } from "@material-ui/core/styles"
import { TimesheetsUTILS } from "../../../../shared/JSutils"
import validate from "../../../../shared/validation"
import EditTimesheet from "./EditTimesheet"
import { useNewInvoiceContext } from "./context"
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const StyledTableCell = withStyles((theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
      padding: '10px 10px 0px 10px !important',
    },
  })
)(TableCell)

const StyledTableRow = withStyles((theme) =>
  createStyles({
    root: {
      "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow)

function StandardTimeTable(props) {
  const { state, services } = useNewInvoiceContext()
  const { hideActions = false } = props
  const timesheetsArr = Object.values(state.selectedTimesheets)
  const [open, setOpen] = React.useState(false);
  const isExternalInvoice = state.invoiceBy == "external"

  if (timesheetsArr?.length < 1) return <span></span>

  // Group timesheets by billing rate
  const groupedByRate = timesheetsArr.reduce((acc, timesheet) => {
    const billingRate = timesheet.billingRate;
    
    if (!acc[billingRate]) {
      acc[billingRate] = {
        serviceSummary: `${services.getNameOfTheEmployeeById(state.employeeID)}-${state.employeeID}`,
        dates: [], // Track all dates for this rate
        quantity: { hours: 0, minutes: 0 },
        rate: billingRate,
        amount: 0,
        totalHrs: 0
      };
    }
    
    // Store the date for range calculation
    acc[billingRate].dates.push(timesheet.date);
    
    if (timesheet.standardTime && timesheet.standardTime !== '00:00') {
      const [hours, minutes] = timesheet.standardTime.split(':').map(Number);
      const amount = parseFloat(billingRate) * (hours + minutes/60);

      acc[billingRate].quantity.hours += hours;
      acc[billingRate].quantity.minutes += minutes;
      if (acc[billingRate].quantity.minutes >= 60) {
        acc[billingRate].quantity.hours += Math.floor(acc[billingRate].quantity.minutes / 60);
        acc[billingRate].quantity.minutes = acc[billingRate].quantity.minutes % 60;
      }

      acc[billingRate].amount += amount;
      acc[billingRate].totalHrs += (hours + minutes/60);
    }
    return acc;
  }, {});

  // Convert to array and sort by rate and date range
  const standardTable = Object.entries(groupedByRate)
    .map(([rate, data]) => {
      // Sort dates for each rate group
      const sortedDates = data.dates.sort((a, b) => new Date(a) - new Date(b));
      const startDate = sortedDates[0];
      const endDate = sortedDates[sortedDates.length - 1];
      
      return {
        ...data,
        activity: `Timesheet (${startDate} - ${endDate})`,
        rate: rate,
        quantity: `${String(data.quantity.hours).padStart(2, '0')}:${String(data.quantity.minutes).padStart(2, '0')}`,
        totalHrs: data.totalHrs.toFixed(2),
        startDate: startDate // Add startDate for sorting
      };
    })
    .sort((a, b) => {
      // First sort by date
      const dateComparison = new Date(a.startDate) - new Date(b.startDate);
      // If dates are equal, then sort by rate
      return dateComparison !== 0 ? dateComparison : parseFloat(a.rate.slice(1)) - parseFloat(b.rate.slice(1));
    });

  const totalAmount = standardTable.reduce((sum, group) => sum + group.amount, 0);

  return (
    <div>
      <>
        <h2>
          <u>Standard Time</u>
        </h2>
        <TableContainer component={Paper}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>S.No</StyledTableCell>
                <StyledTableCell>Service Summary</StyledTableCell>
                <StyledTableCell>Activity</StyledTableCell>
                <StyledTableCell>Quantity(hh:mm)</StyledTableCell>
                <StyledTableCell>Total hours</StyledTableCell>
                <StyledTableCell>Attachment</StyledTableCell>
                {!isExternalInvoice && (
                  <>
                    <StyledTableCell>Rate</StyledTableCell>
                    <StyledTableCell>Amount</StyledTableCell>
                  </>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {standardTable.map((group, index) => (
                <React.Fragment key={group.rate}>
                  <StyledTableRow>
                    <StyledTableCell>
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                      >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                      </IconButton>
                    </StyledTableCell>
                    <StyledTableCell>{group.serviceSummary}</StyledTableCell>
                    <StyledTableCell>{group.activity}</StyledTableCell>
                    <StyledTableCell>{group.quantity}</StyledTableCell>
                    <StyledTableCell>{group.totalHrs}</StyledTableCell>
                    <StyledTableCell>{"No attachment"}</StyledTableCell>
                    {!isExternalInvoice && (
                      <>
                        <StyledTableCell>${group.rate}</StyledTableCell>
                        <StyledTableCell align="right">
                          {validate.currencyFormatterUs(group.amount)}
                        </StyledTableCell>
                      </>
                    )}
                  </StyledTableRow>
                  <StyledTableRow>
                    <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0, border: '0px solid transparent' }} colSpan={8}>
                      <Collapse sx={{ width: '100%' }} in={open} timeout="auto" unmountOnExit>
                        <TableContainer component={Paper}>
                          <Table stickyHeader aria-label="customized table">
                            <TableHead>
                              <TableRow>
                                <StyledTableCell>S.No</StyledTableCell>
                                <StyledTableCell>Service Summary</StyledTableCell>
                                <StyledTableCell>Activity</StyledTableCell>
                                <StyledTableCell>Quantity(hh/mm)</StyledTableCell>
                                <StyledTableCell>Attachment</StyledTableCell>
                                {!isExternalInvoice && (
                                  <>
                                    <StyledTableCell>Rate</StyledTableCell>
                                    <StyledTableCell>Amount</StyledTableCell>
                                  </>
                                )}
                                {!hideActions && <StyledTableCell>Actions</StyledTableCell>}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {timesheetsArr
                                .filter(item => item.billingRate == group.rate)
                                .sort((a, b) => new Date(a.date) - new Date(b.date))
                                .map((item, idx) => {
                                  const { standardTime } = item;
                                  const perDayAmount = services.getTotalAmountForTheTimesheetsAccordingToBillingRate([item]);
                                  return (
                                    <StyledTableRow key={idx}>
                                      <StyledTableCell>{idx + 1}</StyledTableCell>
                                      <StyledTableCell>{group.serviceSummary}</StyledTableCell>
                                      <StyledTableCell>{item.date}</StyledTableCell>
                                      <StyledTableCell>{standardTime}</StyledTableCell>
                                      <StyledTableCell>
                                        {typeof item.attachment !== 'string' && item.attachment?.length > 0 
                                          ? item.attachment.map((e, i) => (
                                              <a key={i} target="_blank" href={e.publicURL}>
                                                <OpenInNewIcon fontSize="small" />
                                              </a>
                                            ))
                                          : typeof item.attachment === 'string' 
                                            ? (
                                                <a target="_blank" href={item.attachment}>
                                                  <OpenInNewIcon fontSize="small" />
                                                </a>
                                              )
                                            : "No attachment"
                                        }
                                      </StyledTableCell>
                                      {!isExternalInvoice && (
                                        <>
                                          <StyledTableCell>
                                            {validate.currencyFormatterUs(item.billingRate)}
                                          </StyledTableCell>
                                          <StyledTableCell align="right">
                                            {validate.currencyFormatterUs(perDayAmount)}
                                          </StyledTableCell>
                                        </>
                                      )}
                                      {!hideActions && (
                                        <StyledTableCell>
                                          <EditTimesheet 
                                            timeSheetDetails={item}
                                            perDayAmount={perDayAmount}
                                            employeeName={services.getNameOfTheEmployeeById(state.employeeID)}
                                            employeeID={state.employeeID}
                                            workedHours={standardTime}
                                            rate={item.billingRate}
                                            type={"standardTime"}
                                          />
                                        </StyledTableCell>
                                      )}
                                    </StyledTableRow>
                                  );
                                })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Collapse>
                    </StyledTableCell>
                  </StyledTableRow>
                </React.Fragment>
              ))}
              {!isExternalInvoice && (
                <TableRow>
                  <TableCell colSpan={6} />
                  <TableCell>
                    <h4>Subtotal</h4>
                  </TableCell>
                  <TableCell align="right">
                    <h4>{validate.currencyFormatterUs(totalAmount)}</h4>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    </div>
  );
}

export default StandardTimeTable